"use client"

import React from "react"
import Image from "next/image"
import Link from "next/link"
import * as Sentry from "@sentry/nextjs"

import { Button } from "@/components/ui/button"

export default function RootError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  React.useEffect(() => {
    // Log the error to an error reporting service
    console.error("the error is:", error)
    Sentry.captureException(error)
  }, [error])

  return (
    <div className="flex min-h-dvh flex-col items-center justify-center gap-8 p-8">
      <h2 className="text-4xl font-semibold">
        <span className="block text-primary md:inline-block">Ooops!</span> ¡Algo
        ha ido mal!
      </h2>
      <p className="max-w-xl text-center text-xl">
        Inténtanlo de nuevo, o contacta con nosotros en{" "}
        <Link
          className="font-semibold underline"
          href="mailto:soporte@wasii.pe"
          target="_blank"
        >
          soporte@wasii.pe
        </Link>{" "}
        para que podamos ayudarte.
      </p>
      <p className="text-xl">¡Mil disculpas!</p>
      <div className="flex items-center gap-4">
        <Button size="lg" onClick={() => reset()}>
          Intentar de nuevo!
        </Button>
        <Link href="/">
          <Button variant="outline" size="lg">
            Volver al inicio
          </Button>
        </Link>
      </div>
      <Image
        src="/images/burning-house.png"
        width={300}
        height={300}
        alt="Burning house"
        priority
      />
      {/* <Link href="/">Go to Home</Link> */}
    </div>
  )
}
